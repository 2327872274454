.box {
    margin-top: 20px;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}

.boxBody {
    padding-right: 10px;
}

.boxPagination {
    margin-top: 10px;
}

.pagination {
    margin-right: 17px;
}
