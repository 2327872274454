:root {
    --primary-color: #0b7b7f;
    --primary-color-scale: rgba(11, 123, 127, 0.35);
    --secondary-color: #e2f3f1;
    --accent-color: #ffffff;
    --default-border-color: #d4d4d4;
    --secandary-background: #eeeeee;
}

.ant-menu-item-selected {
    color: white !important;
    background-color: var(--primary-color) !important;
}

.ant-table-small .ant-table-thead > tr > th {
    background-color: var(--primary-color) !important;
    color: #fff !important
}

.ant-modal-header {
    border-left: 5px solid var(--primary-color) !important;
    border-radius: 0 !important;
    padding-inline-start: 5px !important;
    margin-bottom: 10px !important
}

.ant-table-tbody .ant-table-row .ant-table-cell {
    padding: 6px 6px !important;
}

.base-and-input {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 6px;
    transition: all 0.2s;
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9
}

.base-and-input:hover {
    border-color: #238c8c !important;
    background-color: #ffffff !important;
}

.base-and-input:focus {
    border-color: #0b7b7f;
    box-shadow: 0 0 0 2px rgba(3, 61, 55, 0.33);
    outline: 0;
    background-color: #ffffff;
}

.base-antd-date-picker {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 6px;
    transition: all 0.2s;
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    cursor: text;
}

.base-antd-date-picker:hover {
    border-color: #238c8c;
    background-color: #ffffff;
}

.base-antd-date-picker:focus {
    border-color: #0b7b7f;
    box-shadow: 0 0 0 2px rgba(3, 61, 55, 0.33);
    outline: 0;
    background-color: #ffffff;
}
