.serverItem {
    transition: all .2s;
}

.serverItem:hover {
    box-shadow:  4px 4px 8px 0 rgba(34, 60, 80, 0.05);
    cursor: pointer;
    transition: all .2s;
    background-color: rgb(174, 178, 183, 0.05);
    border-color: var(--primary-color);
}

.serverItemTitle {
    display: flex;
    align-items: center;
}
