.LogoWrapper {
    --margin-btm: -5px;

    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    background-color: var(--accent-color);
}

.LogoSmall {
    max-width: 35px;
    height: 100%;
    max-height: 24px;
    display: inline-flex;
}

.LogoCompanyName {
    max-width: 84px;
    height: 100%;
    margin-bottom: var(--margin-btm);
}

.active {
    width: 100%;
    transition: width .2s;
}

.inactive {
    width: 0%;
    transition: width .2s;
    margin: 0;
}
