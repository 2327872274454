.button {
    border-radius: 15px;
    border: 1px solid var(--accent-color);
    background-image: linear-gradient(to right, #02AAB0 0%, #00CDAC 51%, #02AAB0 100%);
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: .5s;
    background-size: 200% auto;
    box-shadow: 0 0 20px #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.hovered {
    background-position: right center;
    color: var(--accent-color);
    text-decoration: none;
}
.pressed {
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
}

.buttonCaption {
    font-size: 13px;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: 1px;
    color: var(--accent-color);
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.buttonIcon svg {
    width: 18px;
    height: 18px;
    margin-bottom: -3px;
}