.licenceItem {
    transition: all .2s;
}

.licenceItem:hover {
    box-shadow:  4px 4px 8px 0 rgba(34, 60, 80, 0.05);
    cursor: pointer;
    transition: all .2s;
    background-color: rgb(174, 178, 183, 0.05);
    border-color: var(--primary-color);
}

.licenceItemTitle {
    display: flex;
    align-items: center;
}

.expired {
    position: relative;
    overflow: hidden;
    border-color: tomato;
    background-color: rgb(174, 178, 183, 0.05);
    opacity: .7;
}

.expired:hover {
    border-color: tomato;
    opacity: 1;
}

.expired:before {
    content: 'Прекращено';
    font-size: 11px;
    width: 100px;
    height: 20px;
    background-color: tomato;
    color: white;
    padding: 3px;
    border-radius: 0 0 4px 0;
    display: block;
    margin-top: -2px;
    text-align: center;
}