
.toolbarCardWrap {
    min-height: 64px;
    background-color: var(--primary-color);
    box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    margin-bottom: 30px;
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.searchInput button {
    border: 1px solid var(--secondary-color) !important;
}

.searchInput span {
    box-shadow: none !important;
}
