.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-image: url('../../images/backgrounds/mainBackground.svg');
    background-repeat:no-repeat;
    background-size: cover;
}

.content {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.body {
    display: grid;
    --raduis: 10px;
    --padding: 40px;
    min-width: 500px;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: var(--raduis);
}

.greetings {
    background-color: var(--accent-color);
    padding: var(--padding);
    border-radius: var(--raduis) var(--raduis) 0 0;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 110%;
    color: #2a2a2a;
}

.greetingsIcon {
    margin-bottom: calc(var(--padding) / 2);
}

.greetingsButton {
    border-radius: 0 0 var(--raduis) var(--raduis);
    background-color: rgba(255, 255, 255, 0.47);
    padding: var(--padding);
    backdrop-filter: blur(50px);
}

.greetingsCaptionAfter {
    color: #c3c3c3;
    font-size: 13px;
    font-weight: 400;
    line-height: 110%;
    margin-top: 5px;
}